import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { Grid, Container, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

// ----------------------------------------------------------------------
import SearchContext from '../components/context';
import StepTimeline from '../components/widgets';

export default function GettingStarted() {
  const config = useContext(SearchContext);

  return (
    <>
      <Helmet>
        <title> Training | Getting Started </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" gutterBottom>Getting Started</Typography>
            <Typography variant="body2" gutterBottom>To create a new account, you need to provide some information about yourself, your company, and your first project location. This is free and only takes a few minutes. Here are the steps to follow:</Typography>
            <ul style={{marginLeft:'20px'}}>
              <li><Typography variant="body2">Go to <Link color="secondary" href="https://budeesolutions.com/">budeesolutions.com</Link> and click on the “Get Budee for FREE” link in the navigation bar. This will take you to the application page where you can start your free trial.</Typography></li>
              <li><Typography variant="body2">Fill in your personal information, such as your name, email, and password. Make sure you use a valid email address, to receive your login information. Once this is done you can create your first project (there is no limit to the number of projects you can create.</Typography></li>
              <li><Typography variant="body2">Give your project a name and fill in all the company information and choose a location for your first project.</Typography></li>
              <li><Typography variant="body2">You will now have access to the administrative web portal.</Typography></li>
              <li><Typography variant="body2">Now go to app store of your choice and download Budee to your mobile device(s).  Once done simply login using your email address and new password.</Typography></li>
              <li><Typography variant="body2">We recommend then going to the profile training section and watch the short (2 minute video) to personalize your Budee experience.</Typography></li>
            </ul>
            <Typography variant="body2" gutterBottom>You can also watch the video tutorial on this page for a quick introduction on how to register. If you have any questions or need any assistance, please feel free to contact us anytime at <Link color="secondary" href="mailto:info@budeesolutions.com">info@budeesolutions.com</Link>. We hope you enjoy using Budee.</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5">Video Tutorial</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/AjaSDCuDHXM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'10px'}}>
              <Typography variant="h5" style={{flexGrow:1}}>Register Now for Free!</Typography>
              <Typography variant="h6">{config && config.platform === 'web' ? 'Web' : 'Mobile'} - {config && config.user === 'admin' ? 'Admin' : 'Basic'}</Typography>
              <Tooltip title="Select the Platform and User Status in the top left corner, then you can view the perspective on the web or mobile for a basic or administrator.">
                <HelpIcon color="secondary" />
              </Tooltip>
            </div>
            {
              config && config.platform === 'web' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <Typography gutterBottom variant="body2">If you haven't already, proceed to create a new account.  This is free and only requires information related to your user, company and a first project location.</Typography>
                    <StepTimeline
                      title="Quick Reference"
                      list={[...Array(5)].map((_, index) => ({
                        id: `item-${index}`,
                        title: [
                          'Visit app.budeesolutions.com',
                          'Click the "Don\'t have an account? Sign Up" Link',
                          'Create your user account',
                          'Create your company account',
                          'Create your first project',
                        ][index],
                        type: `order${index + 1}`
                      }))}
                     />
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Typography gutterBottom variant="h6">Login</Typography>
                    <Typography variant="body2">Basic user accounts have limited web access, you must acquire your account information from administrators of the application.  Talk to your safety leadership team about getting your login account access.</Typography>
                  </>
                }
              </>
            }
            {
              config && config.platform === 'mobile' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <Typography gutterBottom variant="body2">If you haven't already, proceed to create a new account.  This is free and only requires information related to your user, company and a first project location.</Typography>
                    <Typography gutterBottom variant="h6">Logging In</Typography>
                    <StepTimeline
                      title="Quick Reference"
                      list={[...Array(5)].map((_, index) => ({
                        id: `item-${index}`,
                        title: [
                          'Registration via the Web application is first required',
                          'Visit the appropriate App store either the Google Playstore or Apply Appstore',
                          'Search for "budee solutions"',
                          'Download the mobile app to your device',
                          'Login with your credentials - if you received an email with a username/email/phone the temporary password is budee123'
                        ][index],
                        type: `order${index + 1}`,
                        time: new Date().getTime(),
                      }))}
                     />
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <StepTimeline
                      title="Quick Reference"
                      list={[...Array(4)].map((_, index) => ({
                        id: `item-${index}`,
                        title: [
                          'Visit the appropriate App store either the Google Playstore or Apple Appstore on your device',
                          'Search for "budee solutions"',
                          'Download the mobile app to your device',
                          'Login with your credentials - logins are either a phone number, email or username and the temporary password is budee123'
                        ][index],
                        type: `order${index + 1}`
                      }))}
                     />
                  </>
                }
              </>
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
