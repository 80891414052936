import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Container } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import StepTimeline from '../components/widgets';
import SearchContext from '../components/context';

export default function Rewards() {
  const config = useContext(SearchContext);
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Training | Rewards </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" gutterBottom>Encouraging a Culture of Safety: Dive into Budee's Rewards Module</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Welcome to Budee’s Versatile Rewards Module</Typography>
            <Typography variant="body2" gutterBottom>Ensuring a robust engagement in safety initiatives from your workforce is paramount for both compliance and creating a sustained safety culture. Budee’s Rewards Module isn't just an add-on; it's an integrated, central feature of your comprehensive safety platform, specifically designed to maintain high levels of involvement and motivation among your staff.</Typography>
            <Typography variant="h5" gutterBottom>The Flexibility of Reward Choices</Typography>
            <Typography variant="body2" gutterBottom>Our rewards module is crafted to suit diverse needs. Whether you're considering simple rewards like branded merchandise, or something more enticing like gift cards or experience days, the emphasis is on providing options that resonate with your employees.</Typography>
            <Typography variant="body2" gutterBottom>But the flexibility doesn't stop at the type of rewards. Administrators and supervisors have the latitude to customize rewards tailored for specific roles, projects, or personnel. This ensures that the incentives are not just generic but closely aligned with the unique characteristics and requirements of each work setting.</Typography>
            <Typography variant="h5" gutterBottom>Advanced Planning for Rewards</Typography>
            <Typography variant="body2" gutterBottom>The planning doesn't have to be last-minute. The module allows for the setup of rewards, days, weeks, or even months in advance, providing ample time for both administrators and employees to align their safety goals with the available incentives.</Typography>
            <Typography variant="h5" gutterBottom>Mobile Accessibility and Peer Comparison</Typography>
            <Typography variant="body2" gutterBottom>One of the standout features is the immediate visibility of these rewards. When employees access Budee on their mobile devices, the rewards are prominently displayed, making it impossible to miss. This not only serves as a constant reminder but also allows employees to see how they are faring compared to their colleagues or against predefined reward goals.</Typography>
            <Typography variant="h5" gutterBottom>Conclusion: Building a Reward-Driven Safety Culture</Typography>
            <Typography variant="body2" gutterBottom>Through offering adaptable, tangible incentives and making them highly visible, Budee's Rewards Module cultivates a culture where safety isn't just a mandate, but a shared responsibility that is enthusiastically embraced. It forms a key component in your arsenal for reducing workplace accidents and fostering an environment where safety comes first.</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5">Explanation Video Tutorial</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/tYssOJASua4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Properties</Typography>
            <TableContainer color="secondary" component="div">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Description</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Example Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Title</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Catchy title for the reward to draw employees attention, will be seen on mobile home screen</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Monthly Starbucks Gift Card</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Description</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Longer form description of the reward, some details about what it is and any pertinent information</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Monthly draw for a starbucks gift card, the more you use the app the better a chance you have to win!</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Start</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Date you wish this reward to start in, don’t be afraid to backdate a reward if you forgot to start it when it actually started.  The system will determine all activity that occurred from the start date and initialize the reward appropriately</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>1st day of the month</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">End</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Date you wish this reward to end on, the system will automatically draw names and a list is viewable</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Last day of the month</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <Typography variant="h5" style={{flexGrow:1}}>Quick Reference for Basic Operations</Typography>
              <Typography variant="h6">{config && config.platform === 'web' ? 'Web' : 'Mobile'} - {config && config.user === 'admin' ? 'Admin' : 'Basic'}</Typography>
              <Tooltip title="Select the Platform and User Status in the top left corner, then you can view the perspective on the web or mobile for a basic or administrator.">
                <HelpIcon color="secondary" />
              </Tooltip>
            </div>
            {
              config && config.platform === 'web' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Add a Reward to a Project</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(3)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Rewards"',
                                'Click the "Add New Reward" button',
                                'Fill out the form values and click "Add Reward"'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Add a Reward</Typography>
                           <Typography variant="body2">Navigate to the rewards page in the web console, find the Add button below the table of rewards and click to reveal form..</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/rewards/web-admin-rewards.png" alt="Add a new reward form" />
                           <Typography variant="body2">Fill out all fields for the reward, including uploading a picture of a reward, try to keep the picture a low size like less than 500kb as its loaded in the mobile, use a PNG or JPEG.</Typography>
                           <Typography variant="body2">
                             <ul style={{marginLeft:'25px'}}>
                              <li>Title - Quick catchy phrase to get attention to a new reward.</li>
                              <li>Description - Lengthy description of what the reward is for, or any message to send along with the reward to employees.</li>
                              <li>Image - Try for a PNG or JPEG that is less than 500kb in size.</li>
                              <li>Start Date - When the reward started, if you set a start date in the past any activity that occurred that should have counted towards the reward will be included.  This gives you the ability to backdate a reward if you for example forgot to start the reward on time.</li>
                              <li>End Date - Some time in the future and after the start date.</li>
                              <li>Scoring - Configurable as to what will count towards this reward:.</li>
                              <ul style={{marginLeft:'25px'}}>
                                <li>Certification Submitted - Whenever an employee submits a certification, it will count as 1 towards this reward.</li>
                                <li>Check-In - Whenever a user opens their mobile app to view their home screen, check if there is anything actionable to them this will count as 1 towards this reward.  (Max 1 point per day)</li>
                                <li>Document Read - If an unread policy is read, it will count as 1 toward this reward.</li>
                                <li>Document Submitted - Whenever a document submission is created and submitted, it will count as 1 towards this reward.</li>
                                <li>Document Uploaded - If a document is uploaded, it will count as 1 towards this reward.</li>
                                <li>Notification Acknowledged - If a notification is viewed it wll count as 1 towards this reward.</li>
                                <li>Personal Picture(s) - If a user has personal pictures, or whenever a personal picture is uploaded it will count as 1 for each picture.  (Max 5 points)</li>
                                <li>Profile Picture - If a user has no profile picture and uploads one, or has an existing profile picture it will count as 1 towards the reward.</li>
                                <li>Safety Observation - If a user creates a safety observation, it will count as 1 towards the reward.</li>
                              </ul>
                             </ul>
                             <li>Participation - Leave blank for all members of the project to participate or select specific role(s) and/or contractor(s).  It will be anyone who fits any role or contractor.</li>
                           </Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/rewards/web-admin-reward-add.png" alt="Add a new reward form" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Removing a Reward from a Project</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(3)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Manage Personnel"',
                                'Find the user in the table or search for user by name in the upper left',
                                'Select the user by checking the box in the table',
                                'On the bottom toolbar below the table, click "Move Off-Site"'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Remove a User</Typography>
                           <Typography variant="body2">Users will always be attached to a project for historical context, but once a user is "Off-site" they no longer have access to the project in their mobile devices or web consoles.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/remove-user.png" alt="Remove a user" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>View Reward Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Reward"',
                                'Find the reward(s) in the table',
                                'Select the reward(s) by clicking the row in the table',
                                'View details on a pop-up'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Viewing Reward Details</Typography>
                           <Typography variant="body2">After a reward is created it will end up in the rewards table on the rewards page.  There are radio buttons below the table, you can view past, current, upcomming or all rewards.  Reward rows are clickable and a pop-up will show to give more details.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/rewards/web-admin-rewards.png" alt="Viewing a reward" />
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/rewards/web-admin-reward-detail.png" alt="Viewing a reward" />
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/rewards/web-admin-reward-leaderboard.png" alt="Viewing a reward" />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Typography variant="h6">No Permissions</Typography>
                    <Typography variant="body2">Basic users have no permssions to manage rewards at a job site.</Typography>
                  </>
                }
              </>
            }
            {
              config && config.platform === 'mobile' &&
              <>
                {
                  (config.user === 'admin' || config.user === 'basic') &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Home Screen Rewards</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(2)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Open mobile app and/or select "Home" from bottom navigation or from hamburger menu navigation',
                                'View Reward(s) near bottom of screen'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Viewing Current Rewards</Typography>
                           <Typography variant="body2">Navigate to the home screen and see a carousel of rewards you specifically are participating in.</Typography>
                           <img style={{marginTop:'10px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/rewards/mobile-rewards.png" alt="Home screen rewards" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>View All Rewards</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(3)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Open mobile app and/or select "Home" from bottom navigation or from hamburger menu navigation',
                                'View Reward(s) near bottom of screen',
                                'Click "View" to see all rewards and details'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">View Rewards and Details</Typography>
                           <Typography variant="body2">Navigate to the home screen and see a carousel of rewards you specifically are participating in.  Click the "View Button" to reveal current and past rewards, there is a leaderboard in each reward and you can view probabilities of winning.  A top ranked probability doesn't mean 100% that they win, just that they have the highest chance, its a lottery system at the end so everyone is still in the hunt even with 0.01%.</Typography>
                           <img style={{marginTop:'10px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-manage-personnel.png" alt="Navigation" />
                           <Typography variant="body2">User's require 1 of 3 possible identifiers - an email, phone or username.  All of these are globally unique across Budee, so ensure to pay attention so as not to add a user you're not intending to add even if they exist on another company/project with the same username for example.</Typography>
                           <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/rewards/mobile-rewards.png" alt="View rewards on home" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/rewards/mobile-reward-detail.png" alt="After clicking View see details of all rewards" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/rewards/mobile-reward-leaderboard.png" alt="Scroll to and click 'View leaderboard' to see a leaderboard" />
                           </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
              </>
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
