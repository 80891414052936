import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Container } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import StepTimeline from '../components/widgets';
import SearchContext from '../components/context';

export default function Rewards() {
  const config = useContext(SearchContext);
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Training | Rewards </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" gutterBottom>Alerts Management: Real-Time Safety Communication and Risk Mitigation</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Welcome to Budee's Alerts Module</Typography>
            <Typography variant="body2" gutterBottom>In an industrial landscape where conditions change rapidly, the safety of your workforce remains a constant priority. Budee's Alerts Module is designed to elevate your safety protocols through real-time digital communication. This system enables administrators to swiftly disseminate crucial safety notifications, hazard alerts, emergency response directives, and even training materials.</Typography>
            <Typography variant="h5" gutterBottom>Enhanced Communication Capabilities</Typography>
            <Typography variant="body2" gutterBottom>To enrich your communication strategy, the module allows the inclusion of PDFs, images, and URLs within your alerts.</Typography>
            <Typography variant="h5" gutterBottom>Keeping Track for Improved Safety Compliance</Typography>
            <Typography variant="body2" gutterBottom>An integral part of the module is its robust tracking feature, which provides an audit trail of employee engagement with the alerts. By knowing who has read and acknowledged your notifications, you're not only ensuring compliance but also contributing significantly to risk management.</Typography>
            <Typography variant="h5" gutterBottom>How to Use the Alerts Module</Typography>
            <Typography variant="body2" gutterBottom>To manage your alerts, sign into the administrative portal and proceed to the 'Alerts' section. Here, you have full control over the variety and content of safety notifications sent out for your project location.</Typography>
            <Typography variant="body2" gutterBottom>Utilizing Budee's Alerts Module offers you a streamlined and effective way to manage safety communications, thereby empowering your workforce to operate in a safer, more informed environment.</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5">Video Tutorial</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/EeIaYB68CtQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Properties</Typography>
            <TableContainer color="secondary" component="div">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Description</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Example Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Distributed To</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>
                      <TableContainer sx={{padding:'0px',magin:'0px'}} color="secondary" component="div">
                        <Table sx={{padding:'0px',magin:'0px'}} aria-label="simple table">
                          <TableBody sx={{padding:'0px',magin:'0px'}}>
                            <TableRow sx={{padding:'0px',magin:'0px',border:'0px'}}>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">All</TableCell>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Every member at this project, there are no company wide alerts at this time so this is the largest broadcast group possible</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Administrators</TableCell>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">To all the administrators at this project</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Department</TableCell>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">If users have been assigned to departments, you can broadcast to one or more departments at a time</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Role</TableCell>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">If users have been assigned to roles, you can broadcast to one or more roles at a time</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Contractor</TableCell>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">If users have been assigned to contractors, you can broadcast to one or more contractors at a time</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Single User</TableCell>
                              <TableCell sx={{color:'#ffffff'}} component="th" scope="row">You can add one or more users to the alert</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TableCell>
                    <TableCell sx={{color:'#ffffff'}}>All</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Type of Alert</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>This is the first piece of text the employee will see on their lockscreen when a push notification hits their mobile devices.  This helps give context to what this notification is referring to, you can pick from a small set of commonly used types or choose custom and fill in your own type.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Weather</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Pictures</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>If you want to attach pictures to this alert, one or more will be attached and displayed.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>-</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">PDF Attachment</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>If you want to circulate a PDF document you can attach one.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>-</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Link to Website</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>If you want to have a tappable weblink added to your alert that will send the employee to a specific website you can add one here.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>-</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Alert Text (140 chars maximum)</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>This is the text that will be included as the long form description of the alert.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>For the next 5 days expect temperature lows of -30 degrees celsius, please dress accordingly and take a maximum of 30 consecutive minutes in exposure before warming up inside.</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <Typography variant="h5" style={{flexGrow:1}}>Quick Reference for Basic Operations</Typography>
              <Typography variant="h6">{config && config.platform === 'web' ? 'Web' : 'Mobile'} - {config && config.user === 'admin' ? 'Admin' : 'Basic'}</Typography>
              <Tooltip title="Select the Platform and User Status in the top left corner, then you can view the perspective on the web or mobile for a basic or administrator.">
                <HelpIcon color="secondary" />
              </Tooltip>
            </div>
            {
              config && config.platform === 'web' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Send an Alert to Employees</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(7)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Alerts"',
                                'Setup Distribution for this Alert',
                                'Select Type of Alert',
                                'Optional Picture(s)',
                                'Optional PDF',
                                'Optional HTTP Link',
                                'Fill out Alert message'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Send an Alert</Typography>
                           <Typography variant="body2">Navigate to the Alerts page in the web console, fill out the distribution, type of alert and message.  This will determine to what employees will receive this alert and the information about the alert.  It is optional to add pictures, PDF or include a hyperlink to a website in the Alert.  Alerts are push notifications to the lock screen of a phone and will show up in the notifications of the mobile application.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/alerts/web-admin-form.png" alt="Send a new alert form" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>View Past Alerts and Audit for Acknowledgement</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Alerts" or "Dashboard"',
                                'On "Alerts" page scroll to bottom for table of past alerts and click on a row',
                                'On "Dashboard" page click the Alerts tab for a chart by date of Alerts sent',
                                'Click on date on chart and Alerts sent that date will be in table below, click on a row'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Viewing Past Alerts and Audit</Typography>
                           <Typography variant="body2">In two separate areas of the web application, the Alerts page itself and the Dashboard have data associated with past alerts.  On the Dashboard you can easily control the dates to find the timeline of concern, then by selecting the date on the graph you can view the table below on click on any Alert to see who has acknowledged/read it.  On the Alerts page, is just the total history of Alerts sent below the Alert form.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/alerts/web-admin-table-past.png" alt="View past alerts" />
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/alerts/web-admin-table-acknowledged.png" alt="View Acknowledgement" />
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/alerts/web-admin-dashboard-alert.png" alt="View Dashboard Alerts" />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Typography variant="h6">No Permissions</Typography>
                    <Typography variant="body2">Basic users have no permssions to send alerts at a job site.</Typography>
                  </>
                }
              </>
            }
            {
              config && config.platform === 'mobile' &&
              <>
                {
                  (config.user === 'admin' || config.user === 'basic') &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>View and Acknowledge Alerts</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(2)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Open mobile app and/or select "Notifications" from bottom navigation or from hamburger menu navigation',
                                'View Notification(s) light green colored are Unacknowledged and white is Acknowledged'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Viewing Notifications</Typography>
                           <Typography variant="body2">.</Typography>
                           <img style={{marginTop:'10px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/alerts/mobile-all-list.png" alt="Notifications List" />
                           <img style={{marginTop:'10px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/alerts/mobile-all-details.png" alt="Notification Details" />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
              </>
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
