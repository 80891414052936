import { Helmet } from 'react-helmet-async';

// @mui
import { Grid, Container, Typography } from '@mui/material';
import Link from '@mui/material/Link';

// ----------------------------------------------------------------------

export default function Introduction() {

  return (
    <>
      <Helmet>
        <title> Training | Introduction </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h6" style={{marginTop:'10px',marginBottom:'10px'}}>Welcome to Budee, your comprehensive solution for Occupational Health & Safety (OHS) management.</Typography>
            <Typography variant="body2">Engineered as a sophisticated web application for administrators, Budee also extends its functionality through intuitive mobile apps available on iOS and Android. This multi-layered platform empowers health and safety professionals to effortlessly configure compliance requirements, thereby enabling your workforce to more readily grasp and comply with essential safety protocols. This support site serves as your comprehensive guide to unlocking all that Budee has to offer.</Typography>
            <Typography variant="h6" style={{marginTop:'10px',marginBottom:'10px'}}>Why Opt for Budee?</Typography>
            <Typography variant="body2">Managing safety compliance is a multifaceted endeavor requiring meticulous attention from everyone involved. Budee is designed to alleviate this complexity through its multi-module architecture, each packed with extensive configuration options and robust automations. What sets Budee apart is its potential to be one of the most advanced digital safety tools available today, and this potential can be fully realized with just a modest investment of your time.</Typography>
            <Typography variant="body2">Our training modules are carefully crafted to offer you a deep dive into the feature-rich aspects of each module. Whether you're interested in automated translations in our Documents module or role-specific alerts in our Alerts module, spending time in these training sections will equip you with the knowledge to deploy Budee's capabilities to their fullest extent.</Typography>
            <Typography variant="h6" style={{marginTop:'10px',marginBottom:'10px'}}>How to Utilize This Guide:</Typography>
            <Typography variant="body2">Navigating your way to Budee mastery is simplified through our organized guide. The left-hand navigation offers you four different content focuses: Web, Mobile, Admin, and Basic. Selecting any of these will adapt the guide’s content to fit the chosen platform and user type, offering you targeted and actionable insights. Should you find anything lacking or unclear, we're just an email away for support at <Link href="mailto:supprt@budeesolutions.com" color="secondary"  >support@budeesolutions.com.</Link></Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
