import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import VideoLayout from './layouts/video';
import IntroLayout from './layouts/intro';
//

import Page404 from './pages/Page404';

import Alerts from './pages/Alerts';
import Documents from './pages/Documents';
import Training from './pages/Training';
import ManagePersonnel from './pages/ManagePersonnel';
import GettingStarted from './pages/GettingStarted';
import Introduction from './pages/Introduction';
import Rewards from './pages/Rewards';
import Profile from './pages/Profile';
import Certifications from './pages/Certifications';
import Observations from './pages/Observations';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/intro" />, index: true },
        { path: 'intro', element: <Introduction /> },
        { path: 'started', element: <GettingStarted /> },
        { path: 'profile', element: <Profile /> },
        { path: 'documents', element: <Documents /> },
        { path: 'training', element: <Training /> },
        { path: 'manage-personnel', element: <ManagePersonnel /> },
        { path: 'rewards', element: <Rewards /> },
        { path: 'observations', element: <Observations /> },
        { path: 'certifications', element: <Certifications /> },
        { path: 'alerts', element: <Alerts /> },
      ],
    },
    {
      path: '/video',
      element: <VideoLayout />,
      children: [],
    },
    {
      path: '/intro-slide',
      element: <IntroLayout />,
      children: [],
    },
    {
      path: '*',
      element: <Page404 to="/404" replace />,
    },
  ]);

  return routes;
}
