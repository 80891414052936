import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Container } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';

import StepTimeline from '../components/widgets';
import SearchContext from '../components/context';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Documents() {
  const config = useContext(SearchContext);
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title> Training | Documents </title>
      </Helmet>

      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" gutterBottom>Document Management: Streamlining Your Paperwork and Enhancing Safety Protocols</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Welcome to Budee's Document Management Module</Typography>
            <Typography variant="body2" gutterBottom>In today's digital world, a paperless jobsite isn't just an added bonus—it's a necessity. Budee’s Document Management Module serves as a comprehensive solution for transforming paper-based operations into a streamlined digital workflow.</Typography>
            <Typography variant="h5" gutterBottom>High Configurability for Diverse Needs</Typography>
            <Typography variant="body2" gutterBottom>The Document Management Module is designed for adaptability, boasting 14 different field types to suit your document creation needs. Should you require additional customization, don't hesitate to contact our support team at <Link href="mailto:supprt@budeesolutions.com" color="secondary">support@budeesolutions.com</Link>. We're ready to assist with any missing field types to fit your unique requirements.</Typography>
            <Typography variant="h5" gutterBottom>Essential Video Tutorials and Advanced Capabilities</Typography>
            <Typography variant="body2" gutterBottom>For optimal utilization of this feature, we offer three essential video tutorials. While Budee allows you to create basic forms such as audits and inspections, it also empowers you to develop specialized forms like Incident Reporting, Equipment Inspection Checklists, and Hazard Assessments. These advanced capabilities not only enrich your safety protocols but also introduce intelligent workflows that can drastically improve your overall safety management.</Typography>
            <Typography variant="h5" gutterBottom>Getting Started with Document Management</Typography>
            <Typography variant="body2" gutterBottom>To kickstart your journey, log into the administrative website and navigate to the 'Documents' section. Here you’ll find an all-inclusive dashboard to manage your workforce documentation. To create a new document, simply click the “Add New Document” button located towards the bottom of the page, allowing you to focus on what's really important: ensuring the safety and efficiency of your workforce.</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5">Video Tutorial(s)</Typography>
            <Box sx={{ width: '100%', border: 1, borderColor: theme.palette.primary.dark }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="video tutorials for documents">
                  <Tab sx={{backgroundColor:theme.palette.secondary.main}} label="Basic Document" />
                  <Tab sx={{backgroundColor:theme.palette.secondary.main}} label="Advanced Document" />
                  <Tab sx={{backgroundColor:theme.palette.secondary.main}} label="Upload Document" />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/6ChzoyGkCf8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/DH2KpPDxl2A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/-0dNSdVQ-ps" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
              </CustomTabPanel>
            </Box>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Properties</Typography>
            <TableContainer color="secondary" component="div">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Description</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Example Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Scope</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>In Budee a company can have 2 different scopes of documents.  Documents that are for everyone at the company, as in people across projects and documents that are scoped specifically for a project.  This allows flexibility for commonly used documents that are company wide and project specific documents that only this particular jobsite will understand/use.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Company-Wide</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Private</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>In Budee, once a document is submitted by any users they are essentially publicly available to all users.  In safety it can be useful for people to be able to see other submissions, understand what is going on and be aware of reports.  In some cases, only administrative staff should see document submissions, by checking off that this is a private document only administrative staff will see submissions.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Non-Private</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Edit Locked</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>In Budee, once a document is submitted it can still be edited by the submitter and any/all administrative staff.  If the edit locked is toggled, only administrative staff will be able to edit a submission after its published by the original user.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Locked</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Required By</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>In Budee, we attempt to ensure users only see relevant documents to their job, if you want a document to be available to everyone simply leave it as Project Wide.  If you want only certain users who have been assigned a specific role or a contractor to be able to submit to you can select as many roles/contractors as you like and only users with those assigned role or contractor will see the document available to them.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Role: OHS Inspector</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Name</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>This is the descriptive and recognizable name employees will recognize when viewing documents.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Field Safety Audit</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Description</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>This is any additional, even long form description of the document.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>An audit that is required to be done monthly for all projects.</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h5" gutterBottom>Advanced Properties</Typography>
            <TableContainer color="secondary" component="div">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Description</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Example Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Score</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Putting in an integer into this text box will indicate that if this option is selected it will contribute to the overall score of the submission.  A empty or null option amongst other options with an integer present will be considered a not applicable or non-scoring event.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Pass - 1, Fail - 0, N/A - Null (Empty)</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Color</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>This is for print layout, any color selected for an option will then be included in the print view, a common use case here is green for a good answer and a red for a bad answer.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Green for Pass, Red for Fail</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Observation Trigger</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>When you click on the observation icon, a dialog pops up and if you accept or click the big green button that says “Add Required Observation” this option is now set up as a trigger to record an observation.  We will go over the mobile view next to show what this experience is like.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Fail - when the field value of fail is selected, record an observation (task) to correct the failure</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Message Trigger</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>When you click on the chat icon, a dialog pops up with fields for Title and Description.  This message will be alerted on the mobile phone to draw emphasis.  Oftentimes in safety there needs to be reminders to get everyone to talk about and remember proper procedure or general mindfulness.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Title: "Rule the Tool" Description: "Keep hands free from strike zones of hammers, always wear hearing protection and no loose clothing when operating drills.""</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Required Signatures</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>For signatures the User and User List are the only fields that support a required signature flow.  Any user or users that end up in an activated signature field will be prompted for signatures on their mobile devices.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Supervisor Field for sign-off</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <Typography variant="h5" style={{flexGrow:1}}>Quick Reference for Basic Operations</Typography>
              <Typography variant="h6">{config && config.platform === 'web' ? 'Web' : 'Mobile'} - {config && config.user === 'admin' ? 'Admin' : 'Basic'}</Typography>
              <Tooltip title="Select the Platform and User Status in the top left corner, then you can view the perspective on the web or mobile for a basic or administrator.">
                <HelpIcon color="secondary" />
              </Tooltip>
            </div>
            {
              config && config.platform === 'web' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Add a Document(s)</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(3)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Documents"',
                                'Click the "Documents" Tab',
                                'Below table of documents or bottom of screen is "Add New Document" button',
                                'Fill out form and click Add'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Add a Document</Typography>
                           <Typography variant="body2">Any document used in safety processes can be modeled here for digitization, it will then be available on the mobile app for submissions to be created and completed.</Typography>
                           <Typography variant="body2">
                             <ul style={{marginLeft:'25px'}}>
                              <li>Scope - Is this document to be available to all projects at the company or is it specific to this project?</li>
                              <li>Private - Once a submission is made for this document on the mobile app, are they viewable by both Admin/Basic or only Admin?</li>
                              <li>Edit Locked - Once a submission is completed by a basic user, are they able to edit it afterwards?</li>
                              <li>Required By - If this document is only intended for certain roles or contractors, they can be selected and added here.  Document shows to everyone unless a role/contrator is added.</li>
                             </ul>
                           </Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/web-admin-add-doc.png" alt="Add a new user form" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Add Field(s) to a Document</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(6)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Documents"',
                                'Click the "Documents" Tab',
                                'A document must be in Draft to edit fields, newly created documents are automatically in draft until published.',
                                'To add a field to an existing document, click the "Edit Fields/New Version" on the right column.',
                                'Select Field Types and example screenshot of field on mobile will be shown',
                                'Fill out details and add'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Add a Field to a Document</Typography>
                           <Typography variant="body2">Documents are composed of fields, a input type of text for any small string, date picker, timestamp, etc. these field types should represent any typical field found in a document.  If anything is missing, please contact <Link href="mailto:supprt@budeesolutions.com" color="secondary">support@budeesolutions.com</Link> to have this investigated and potentially add a new field to meet your requirement.</Typography>
                           <Typography variant="body2">
                             <ul style={{marginLeft:'25px'}}>
                              <li>Help Tip - This will be a tooltip that is touch to alert the details about this particular field for employee information.</li>
                              <li>Page Width - This is for web layout and is really more important with respect to printing.  When you print out submissions, you can really control the layout here by setting page width in terms of 3rds.  Line breaks are always inserted if an element won't fit in the row give its page width.</li>
                              <li>Options - These are for Check Lists, Pickers or Radio Groups.</li>
                              <li>Indexing - In the list of fields, there is a magnifying glass that if clicked on input type text, this will become an indexed field that is searchable by value.  Think workorder # or some kind of identifier you want to be able to quickly search for submissions.</li>
                              <ul style={{marginLeft:'25px'}}>
                                <li>Score - An integer value is attachable to a particular option, all scored options are tallied at the end of a submission</li>
                                <li>Required Observation - By clicking the eyeball icon, you can attach a required safety observation (assignable task) to be filled out.</li>
                                <li>Alert - By clicking the text dialog, a pop-up alert will be displayed when this option is selected.  Often a helpful hint or potentially indicating required additional workflow.</li>
                              </ul>
                             </ul>
                           </Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/web-admin-field.png" alt="Add a new field to a document" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Archive a Document</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(3)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Documents"',
                                'Find the document in the table listing all documents',
                                'Click the Archive button'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Archive Document</Typography>
                           <Typography variant="body2">When a document is no longer needed, you can archive it and remove it from view.  Documents exist forever, if you need to unarchive email support@budeesolutions.com (Feature will be added for self-service)</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/web-admin-doc-archive.png" alt="Archive a document" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Edit a Document</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Documents"',
                                'Find and Click on the document in the table listing all documents',
                                'At the top of the page next to the title is the "Edit" button',
                                'Change details about the document and click update'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Edit Document</Typography>
                           <Typography variant="body2">If you need to change the name, description of some of the properties of a document you can edit them here.  The only field that is not editable is the scope of the document, if this is a large and/or complicated document and you want to change the scope of it, please reach out to support@budeesolutions.com and we can likely assist.  The limitation stems from a database constraint that gives the tradeoff of fast read times but not editable from one scope to the other.  Potentially a feature in the future to meet this requirement.</Typography>
                           <img style={{marginTop:'10px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/web-admin-doc-view.png" alt="View a document" />
                           <img style={{marginTop:'10px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/web-admin-doc-edit.png" alt="Edit a document" />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Accordion>
                      <AccordionSummary
                        style={{backgroundColor:theme.palette.primary.main}}
                        expandIcon={<ExpandMoreIcon color="secondary" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{color:'white'}}>Upload a Document(s)</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                        <StepTimeline
                          title="Upload a Document(s)"
                          list={[...Array(3)].map((_, index) => ({
                            id: `item-${index}`,
                            title: [
                              'Navigate to "Documents"',
                              'Click the "Upload" Tab',
                              'Fill out the form and attach PDF(s) or Image(s)'
                            ][index],
                            type: `order${index + 1}`
                          }))}
                         />
                         <Typography variant="h6">Web Document Upload</Typography>
                         <Typography variant="body2">A basic user has both web and mobile to upload any documents they feel should be submitted into safety record.  This page provides a form to accomplish just that, upload as many PDF(s) or Image(s) as needed and provide a descriptive title.</Typography>
                         <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/web-basic-upload.png" alt="Upload a document" />
                      </AccordionDetails>
                    </Accordion>
                  </>
                }
              </>
            }
            {
              config && config.platform === 'mobile' &&
              <>
                {
                  (config.user === 'admin' || config.user === 'basic') &&
                  <>
                    <Accordion>
                      <AccordionSummary
                        style={{backgroundColor:theme.palette.primary.main}}
                        expandIcon={<ExpandMoreIcon color="secondary" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{color:'white'}}>View Documents and Submissions</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                        <StepTimeline
                          title="Quick Reference"
                          list={[...Array(4)].map((_, index) => ({
                            id: `item-${index}`,
                            title: [
                              'Navigate to "Documents" on bottom navigation',
                              'View the list of documents',
                              'Tap the user with watch to view your past submissions',
                              'Tap under the title on the watch to view all submissions for that document'
                            ][index],
                            type: `order${index + 1}`
                          }))}
                         />
                         <Typography variant="h6">Document Submissions</Typography>
                         <Typography variant="body2">In the bottom navigation there is a paper or document icon, touch to navigate to the document submission screen.  On this screen is a list of documents with a toolbar below each name, from left to right the actions are: create, view description, view past submissions and who is required to fill out this document.  If you want to view all of your past submissions chronologically click the user with clock icon at the top of the list.  Once a document is submitted, all administrators will be notified of the new submission.</Typography>
                         <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                           <img width="200px" style={{marginTop:'10px',marginLeft:'5px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/mobile-doc-list.png" alt="List of documents" />
                           <img width="200px" style={{marginTop:'10px',marginLeft:'5px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/mobile-doc-desc.png" alt="Description of document" />
                           <img width="200px" style={{marginTop:'10px',marginLeft:'5px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/mobile-all-history.png" alt="All submission history of document" />
                           <img width="200px" style={{marginTop:'10px',marginLeft:'5px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/mobile-doc-required.png" alt="Intended audience" />
                           <img width="200px" style={{marginTop:'10px',marginLeft:'5px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/mobile-your-history.png" alt="Your history of submissions" />
                         </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        style={{backgroundColor:theme.palette.primary.main}}
                        expandIcon={<ExpandMoreIcon color="secondary" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{color:'white'}}>Create a Submission</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                        <StepTimeline
                          title="Quick Reference"
                          list={[...Array(5)].map((_, index) => ({
                            id: `item-${index}`,
                            title: [
                              'Navigate to "Documents"',
                              'Click the "Paper with Pen" Icon to create a new submission',
                              'Attachments are the paper clip icon at top',
                              'The checkmark is for submitting the completed document',
                              'If you hit back or the app crashes, you can find your in progress submission under the title of the document in the list'
                            ][index],
                            type: `order${index + 1}`
                          }))}
                         />
                         <Typography variant="h6">Document Submissions</Typography>
                         <Typography variant="body2">In the bottom navigation there is a paper or document icon, touch to navigate to the document submission screen.  On this screen is a list of documents with a toolbar below each name, touch the leftmost icon which is a paper and pen icon to create a new submission.  Once a submission is created you'll see all the fields to fillout, you can also have attachments by clicking the paperclip icon at the top and when you are completed filling out the document, the checkmark icon on top right is the submit button.  If the app crashed or you hit the back button, you can see in a screenshot below there is a draft that is dated when you began.  This way no data is lost, you can pickup where you left off and if its a document that you're gathering information for throughout your day submit at the end of the day when its completed.</Typography>
                         <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                           <img width="200px" style={{marginTop:'10px',marginLeft:'5px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/mobile-complete-submission.png" alt="List of documents" />
                           <img width="200px" style={{marginTop:'10px',marginLeft:'5px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents/mobile-doc-draft.png" alt="Submission in draft" />
                         </div>
                      </AccordionDetails>
                    </Accordion>
                  </>
                }
              </>
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
