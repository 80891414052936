import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Container } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import StepTimeline from '../components/widgets';
import SearchContext from '../components/context';

export default function DocumentsReadonly() {
  const config = useContext(SearchContext);
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Training | Training Materials </title>
      </Helmet>

      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" gutterBottom>Training Management: Simple, Cost-Effective Learning Solutions for Enhanced Safety</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Welcome to Budee's Training Management Module</Typography>
            <Typography variant="body2" gutterBottom>Safety education is essential for a secure and efficient work environment. Budee's Training Management Module serves as your go-to platform for disseminating critical safety information across your workforce. While not a full-fledged Learning Management System, Budee provides an elegantly simple, user-friendly, and cost-effective solution for your training needs.</Typography>
            <Typography variant="h5" gutterBottom>Media Formats Supported</Typography>
            <Typography variant="body2" gutterBottom>The module is compatible with two primary training formats: PDF reading materials and MP4 videos.</Typography>
            <Typography variant="h5" gutterBottom>Ensuring Focused Engagement with Videos</Typography>
            <Typography variant="body2" gutterBottom>Importantly, the video playback features are designed to prevent fast-forwarding or muting, thereby ensuring complete viewing and comprehension of all crucial safety information.</Typography>
            <Typography variant="h5" gutterBottom>Audit Trail for Compliance</Typography>
            <Typography variant="body2" gutterBottom>Our system comes with robust tracking and auditing functionalities, allowing administrators to monitor who has accessed and completed the provided training materials. This creates a reliable audit trail for compliance checks.</Typography>
            <Typography variant="h5" gutterBottom>Navigating the Training Module</Typography>
            <Typography variant="body2" gutterBottom>To manage your training materials, simply log into the administrative portal and navigate to the 'Training' section. Here, you can upload, update, and track all relevant training resources for your team.</Typography>
            <Typography variant="body2" gutterBottom>By taking advantage of Budee's Training Management Module, you're making a meaningful contribution to the ongoing safety education of your workforce—ensuring compliance, reducing risks, and fostering a culture of proactive safety, all while keeping costs low.</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5">Video Tutorial</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/vfeMHhwPANA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Properties</Typography>
            <TableContainer color="secondary" component="div">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Description</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Example Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Scope</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>In Budee a company can have 2 different scopes of training.  Training materials that are for everyone at the company, as in people across projects and training materials that are scoped specifically for a project.  This allows flexibility for commonly used training materials that are company wide and project specific training materials that only this particular job site will understand/use.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Project-Wide</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Required By</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>In Budee, we attempt to ensure users only see relevant documents to their job, if you want a certification to be required by everyone simply leave it as Project Wide.  If you want only certain users who have been assigned a specific role or a contractor to be required to submit you can select as many roles/contractors as you like and only users with those assigned role or contractor will see the document available to them.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Project-Wide</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Name</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>This is the descriptive and recognizable name employees will recognize when viewing documents.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Logistic Level 1</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Description</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>This is any additional, even long form description of the document.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>A site level map of level 1 of the structure, find the Medic Trailer, Gates A/B, Stairwells C/D, Medic Trailer and Bathroom locations</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Type</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Select which media type you want to create a training material for.  For Video (MP4) it is required to indicate which language the video is in, for PDF it is automatically detected from the text.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>PDF</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <Typography variant="h5" style={{flexGrow:1}}>Quick Reference for Basic Operations</Typography>
              <Typography variant="h6">{config && config.platform === 'web' ? 'Web' : 'Mobile'} - {config && config.user === 'admin' ? 'Admin' : 'Basic'}</Typography>
              <Tooltip title="Select the Platform and User Status in the top left corner, then you can view the perspective on the web or mobile for a basic or administrator.">
                <HelpIcon color="secondary" />
              </Tooltip>
            </div>
            {
              config && config.platform === 'web' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Add a Document</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(5)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Documents"',
                                'Click the "Readonly" Tab',
                                'Below the table of documents or the bottom of the page is an "Add New Document"',
                                'Enter in the title, description, scope and the intended audience for this reading material',
                                'Click "Add Required Document"'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Add a Readonly Document</Typography>
                           <Typography variant="body2">When distributing policies, orientation materials or any generic documents that you want your project members to read can be accomplished by adding in readonly documents.  Keeping reading materials scoped properly with the correct intended audience will dramatically increase your chances of all staff reading the materials that are important to their specific jobs.  Having large manuals with information that could be broken down into smaller documents and targetted to the correct staff means your staff will be more engaged in their reading materials, knowing it has relevancy to their actual job.</Typography>
                           <Typography variant="body2">
                             <ul style={{marginLeft:'25px'}}>
                              <li>Scope - Is this document to be available to all projects at the company or is it specific to this project?</li>
                              <li>Required By - If this document is only intended for certain roles or contractors, they can be selected and added here.  Document shows to everyone unless a role/contrator is added.</li>
                             </ul>
                           </Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents-ro/web-admin-view-readonly.png" alt="View all documents and add new" />
                           <Typography variant="body2">After clicking the "Add New Document", fill out the required fields.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents-ro/web-admin-create-doc.png" alt="Add a new document form" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Removing a Document</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(3)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Documents"',
                                'Click the "Readonly" Tab',
                                'Find the document in the table and click on the "Archive" button'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Archive a Document</Typography>
                           <Typography variant="body2">After archival a document is no longer visible to anyone, including administrators.  These documents have a permanent storage and can be unarchived or viewed for audit purposes.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents-ro/web-admin-view-doc.png" alt="View all documents and archive" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>View Audit Trail of User(s)</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Dashbaord"',
                                'Click the "Readonly" Tab',
                                'View the piecharts of every document and can see what portion of the required staff have read it',
                                'Click on the document to get detailed breakdown below of required users'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Viewing All Documents Pie Charts</Typography>
                           <Typography variant="body2">By visiting the dashboard and viewing all the documents, you can see high level pie charts indicating the portion of required users that has read that document.  By clicking on the pie chart you will get the breakdown of the data below to see specific names of employees and timestamps of when they read it.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents-ro/web-admin-view-dashbaord.png" alt="Viewing audit history of a document" />
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Documents"',
                                'Click the "Readonly" Tab',
                                'Find the user(s) in the table or search for user by name in the upper left',
                                'Find the document in the table and click on it'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Viewing a Single Document</Typography>
                           <Typography variant="body2">The table that lists all the readonly documents can show detailed information by clicking on a row.  This will pop-up a dialog to show a detailed breakdown of who has read and when they read it.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/documents-ro/web-admin-view-doc.png" alt="Viewing audit history of a document" />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Typography variant="h6">No Permissions</Typography>
                    <Typography variant="body2">Basic users have no permssions to configure readonly documents at a job site.</Typography>
                  </>
                }
              </>
            }
            {
              config && config.platform === 'mobile' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Add a User(s) to a Project</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Manage Personnel"',
                                'Fill out user login information',
                                'Select status, role and/or contractor',
                                'Fill out emergency contact information'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Add a User</Typography>
                           <Typography variant="body2">Navigate to the manage personnel screen</Typography>
                           <img style={{marginTop:'10px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-manage-personnel.png" alt="Navigation" />
                           <Typography variant="body2">User's require 1 of 3 possible identifiers - an email, phone or username.  All of these are globally unique across Budee, so ensure to pay attention so as not to add a user you're not intending to add even if they exist on another company/project with the same username for example.</Typography>
                           <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-add.png" alt="Add a new user form" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-add-role.png" alt="Set user role" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-add-status.png" alt="Set user status" />
                           </div>
                           <Typography variant="h6">Add User Emergency Contact</Typography>
                           <Typography variant="body2">On the last step you can add in a users emergency contact information.</Typography>
                           <img style={{marginTop:'10px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-add-emerg-contact.png" alt="Set user emergency contact information" />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Typography variant="h6">No Permissions</Typography>
                    <Typography variant="body2">Basic users have no permssions to manage personnel at a job site.</Typography>
                  </>
                }
              </>
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
