import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Container } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import StepTimeline from '../components/widgets';
import SearchContext from '../components/context';

export default function ManagePersonnel() {
  const config = useContext(SearchContext);
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Training | Manage Personnel </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" gutterBottom>Personnel Management: Efficient Onboarding and User Administration</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Welcome to Budee's Personnel Management Module</Typography>
            <Typography variant="body2" gutterBottom>Maintaining an updated roster of employees is a continuous administrative task, especially in dynamic work environments where personnel are frequently coming and going. Budee's Personnel Management Module makes this task easier, providing administrators with a quick and straightforward way to add, edit, or remove users from the safety platform.</Typography>
            <Typography variant="h5" gutterBottom>Multiple Ways to Add Personnel</Typography>
            <Typography variant="body2" gutterBottom>Administrators have multiple options to add personnel: three via the desktop interface and one more through the mobile application. These methods are centralized within the administrative website for ease of management.</Typography>
            <Typography variant="h5" gutterBottom>Flexible Login Options</Typography>
            <Typography variant="body2" gutterBottom>Users can be added to the system by assigning a unique email, phone number, or username, allowing for versatility in how employees can log in. While some users may not prefer to use their personal email or phone number, they can opt for a username instead. However, it's worth noting that usernames are the least recommended option for password recovery reasons. For accounts linked to an email or phone, password resets can be easily done by the user. With usernames, administrators will need to intervene for password resets.</Typography>
            <Typography variant="h5" gutterBottom>Ensuring Security and Ease of Use</Typography>
            <Typography variant="body2" gutterBottom>The multiple login options are designed to provide utmost flexibility, catering to diverse user preferences while maintaining security protocols.</Typography>
            <Typography variant="body2" gutterBottom>By employing Budee's Personnel Management Module, administrators can effectively manage the ever-changing landscape of personnel, ensuring that everyone—whether new hires or those rotating off-site—are accurately accounted for in your safety platform.</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5">Video Tutorial</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/XI-YkccWpWo" title="Managing Personnel Tutorial" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Properties</Typography>
            <TableContainer color="secondary" component="div">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Description</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Example Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Email</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>User's email address, 1 of 3 fields a user can login with.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>msamuels@budeesolutions.com</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Phone</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Users phone number, 1 of 3 fields a user can login with, be sure to select correct country code as there is validation done to ensure it is a potentially real phone number.  Even if both Canada and USA are +1 international code, you require correct country selected for phone number validation of area code.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>+14035556666</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Username</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Free form username field, 1 of 3 fields a user can login with, sometimes a group of people will be logging in and other times an employee refuses to provide email or phone, this is a last resort in that scenario to create a login for an employee.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>warehouse-01</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">First Name</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>First name of the employee.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Mattie</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Last Name</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Last or Family name of the employee.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Samuels</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h5" gutterBottom>Additional Properties</Typography>
            <TableContainer color="secondary" component="div">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Description</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Example Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Status</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Administrator or Basic, basic users have significantly limited access to the application.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>-</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Role</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>A job title or grouping of employees with similar safety requirements</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Electrician, Truck Driver, ...</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Department</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Any department or perhaps a shift #.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Field, HR, Executive,... Day Shift, Night Shift</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Contractor</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>3rd party company that may be visiting or remaining on site, with specific safety requirements.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Any Contractor Company Name</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">On-Site/Off-Site</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>When an employee is on-site, they are included in all areas of the application and monitored for compliance.  If an employee is terminated from their job or no longer visiting the job-site move them off-site to be excluded from billing.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>On-Site/Off-Site</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <Typography variant="h5" style={{flexGrow:1}}>Quick Reference for Basic Operations</Typography>
              <Typography variant="h6">{config && config.platform === 'web' ? 'Web' : 'Mobile'} - {config && config.user === 'admin' ? 'Admin' : 'Basic'}</Typography>
              <Tooltip title="Select the Platform and User Status in the top left corner, then you can view the perspective on the web or mobile for a basic or administrator.">
                <HelpIcon color="secondary" />
              </Tooltip>
            </div>
            {
              config && config.platform === 'web' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Add a User(s) to a Project</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(3)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Manage Personnel"',
                                'Click the "Invite Users" Tab',
                                'Can add a single user by filling out form fields or bulk add users via CSV'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Add a Single User</Typography>
                           <Typography variant="body2">User's require 1 of 3 possible identifiers - an email, phone or username.  All of these are globally unique across Budee, so ensure to pay attention so as not to add a user you're not intending to add even if they exist on another company/project with the same username for example.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/username-exists.png" alt="Add a new user form" />
                           <Typography variant="body2">Below is an example of what shows up when you type an existing username, phone or email.  If it is the user you intend, just click the add and the user will be added to the project selected in the upper left hand corner.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/add-user.png" alt="Add a new user form" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Removing a User(s) from a Project</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Manage Personnel"',
                                'Find the user in the table or search for user by name in the upper left',
                                'Select the user by checking the box in the table',
                                'On the bottom toolbar below the table, click "Move Off-Site"'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Remove a User</Typography>
                           <Typography variant="body2">Users will always be attached to a project for historical context, but once a user is "Off-site" they no longer have access to the project in their mobile devices or web consoles.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/remove-user.png" alt="Remove a user" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Change a User(s) Status, Role, Contrator or Department</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Manage Personnel"',
                                'Find the user(s) in the table or search for user by name in the upper left',
                                'Select the user(s) by checking the box in the table',
                                'On the bottom toolbar below the table, select from which dropdown you want to edit: Status, Role, Contractor or Department'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Setting User(s) Traits</Typography>
                           <Typography variant="body2">After user(s) have been added to a project, it is important to setup their accounts with the proper user status, role, contractor or department.  Status of being a 'Basic' or 'Administrator' gives them the appropriate permission levels for the application.  When setting the role of a user, this will correspond to what documents, certifications, rewards, etc. they will be eligible to view/edit.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/traits.png" alt="Setting up a user" />
                           <Typography variant="h6">Status</Typography>
                           <Typography variant="body2">Administrators have full web console access, they are given superuser type privileges and are only to be members of your safety administration staff.  Basic users are typically the rest of the employees onsite, they are expected to fill out documents, follow processes and submit the various pieces of information that will be collected by safety administration staff.</Typography>
                           <Typography variant="h6">Role</Typography>
                           <Typography variant="body2">Roles are to relate similar employees to each other in groups, like Welder/Iron Worker/etc. each role will have their own configurable requirements such that when they use their mobile app the exact safety requirements can be displayed to the user.  This tool attempts to ensure employees only see relevant information to their job, so that they can manage the information and fulfill their requirements on the job site.</Typography>
                           <Typography variant="h6">Contractor</Typography>
                           <Typography variant="body2">Contractors can be slightly different as they are not always under the same umbrella that a regular employee of your company is.  It is also featured in the document uploads, as contractors typically have their own safety processes and when they want to submit their documents to you they can upload them and tag them as the contractor to maintain the audit trail and a single source of information for the job site.</Typography>
                           <Typography variant="h6">Department</Typography>
                           <Typography variant="body2">This is just one extra field to be used for managing groups of users, currently there are few features tied to department but it can be useful for some companies to include this categorization.</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Typography variant="h6">No Permissions</Typography>
                    <Typography variant="body2">Basic users have no permssions to manage personnel at a job site.</Typography>
                  </>
                }
              </>
            }
            {
              config && config.platform === 'mobile' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Add a User(s) to a Project</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Manage Personnel"',
                                'Fill out user login information',
                                'Select status, role and/or contractor',
                                'Fill out emergency contact information'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Add a User</Typography>
                           <Typography variant="body2">Navigate to the manage personnel screen</Typography>
                           <img style={{marginTop:'10px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-manage-personnel.png" alt="Navigation" />
                           <Typography variant="body2">User's require 1 of 3 possible identifiers - an email, phone or username.  All of these are globally unique across Budee, so ensure to pay attention so as not to add a user you're not intending to add even if they exist on another company/project with the same username for example.</Typography>
                           <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-add.png" alt="Add a new user form" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-add-role.png" alt="Set user role" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-add-status.png" alt="Set user status" />
                           </div>
                           <Typography variant="h6">Add User Emergency Contact</Typography>
                           <Typography variant="body2">On the last step you can add in a users emergency contact information.</Typography>
                           <img style={{marginTop:'10px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-add-emerg-contact.png" alt="Set user emergency contact information" />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Typography variant="h6">No Permissions</Typography>
                    <Typography variant="body2">Basic users have no permssions to manage personnel at a job site.</Typography>
                  </>
                }
              </>
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
