import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import StepTimeline from '../components/widgets';
import SearchContext from '../components/context';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ManagePersonnel() {
  const config = useContext(SearchContext);
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title> Training | Profile </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" gutterBottom>Budee's User Profile: Making Safety Personal</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Making Safety Personal</Typography>
            <Typography variant="body2" gutterBottom>Welcome to the Personalization Module of the Budee Safety App. We understand that while safety is crucial, it might not always be the first thing on a worker's mind. To combat this, we've introduced a feature that allows workers to upload photos of their loved ones directly into the app. These photos serve as a compelling visual reminder of why safety is non-negotiable: to ensure that everyone returns home safely to their families. This emotional connection aims to boost safety awareness and reduce the likelihood of accidents or risky behaviors at work.</Typography>
            <Typography variant="h5" gutterBottom>Emergency Contact Quick Access for Critical Moments</Typography>
            <Typography variant="body2" gutterBottom>Navigating through emergencies is an inherently stressful process. That's why our Emergency Contact Module is designed to make vital information easily accessible for administrators. With this feature, administrators (supervisors, safety managers, etc., anyone with admin access) can instantly look up any employee by name to access their emergency contact information. If the worker has also indicated that they may have a medical condition or concern, that can also be accessed for safer triaging.  Moreover, a quick-link option is available that will automatically launch the phone app to initiate a call. This convenience ensures that administrators have all the necessary contact information right at their fingertips when every second counts.</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5">Video Tutorial(s)</Typography>
            <Box sx={{ width: '100%', border: 1, borderColor: theme.palette.primary.dark }}>
              <Box sx={{ borderBottom: 1, borderColor: theme.palette.primary.dark }}>
                <Tabs value={value} onChange={handleChange} aria-label="video tutorials for profile and emergency contact">
                  <Tab sx={{backgroundColor:theme.palette.secondary.main}} label="Personalization" />
                  <Tab sx={{backgroundColor:theme.palette.secondary.main}} label="Emergency Contact" />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/5igYK8mOaRI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/zHGtDOJPzsk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowfullscreen"></iframe>
              </CustomTabPanel>
            </Box>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Properties</Typography>
            <TableContainer color="secondary" component="div">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Description</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Example Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Relationship</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>What is the relationship of the contact listed to the employee like father, wife, etc.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Father</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Name</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>What is the name of the contact listed</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>John Smith</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Phone</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>What is the phone number the contact can be reached at, please be aware there is some logic here to ensure the correct country code and a valid phone number is provided</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>+14034445555</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Medications</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Any relevant medication information the employee has provided that emergency responders would want to know</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Insulin</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Allergies</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Any relevant allergy information the employee has provided that emergency responders would want to know</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Penicillin</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Ethnicity</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Of the emergency contact</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Caucasian</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">City of Residence</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Of the emergency contact</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Boston</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Gender</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Of the emergency contact</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Male</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <Typography variant="h5" style={{flexGrow:1}}>Quick Reference for Basic Operations</Typography>
              <Typography variant="h6">{config && config.platform === 'web' ? 'Web' : 'Mobile'} - {config && config.user === 'admin' ? 'Admin' : 'Basic'}</Typography>
              <Tooltip title="Select the Platform and User Status in the top left corner, then you can view the perspective on the web or mobile for a basic or administrator.">
                <HelpIcon color="secondary" />
              </Tooltip>
            </div>
            {
              config && config.platform === 'web' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Edit Your Emergency Contact</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Manage Personnel"',
                                'Find the user(s) in the table or search for user by name in the upper left',
                                'Select the user(s) by clicking the icon on the far right of the row',
                                'Pop-up will contain form fields for updating'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Setting Employees Emergency Contact</Typography>
                           <Typography variant="body2">After user(s) have been added to a project, it is important to ensure they have an emergency contact.  Finding the employee in the table and clicking on the profile icon - not any where else on the row - will bring up the pop-up for editing this information.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/web-admin-select-user.png" alt="Finding a user" />
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/web-admin-edit-emerg-contact.png" alt="Edit emergency contact information and save" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Password Reset of Employee</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(5)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Manage Personnel"',
                                'Find the user(s) in the table or search for user by name in the upper left',
                                'Select the user(s) by clicking the icon on the far right of the row',
                                'Pop-up will contain button for "Password Reset"',
                                'Password must be 6 characters or more'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Password Reset</Typography>
                           <Typography variant="body2">If an administrator needs to reset a users password, find them in the table under Manage Personnel and click on the profile icon - not any where else on the row - to reveal a pop-up with Password Reset button.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/web-admin-select-user.png" alt="Finding a user" />
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/web-admin-password-reset.png" alt="Reset to a new password" />
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/web-admin-password-reset-new.png" alt="Set password and save" />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Typography variant="h3">No Features</Typography>
                    <Typography variant="body2">Basic users can edit their profile information exclusively on the mobile application not the web.</Typography>
                  </>
                }
              </>
            }
            {
              config && config.platform === 'mobile' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Lookup user for Emergency Contact</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Hamburge Menu Navigate (Top Right) to "Emergency Contact"',
                                'Find user by name or in list of all users',
                                'Tap user in list',
                                'Presented with Emergency Contact information and tap to phone'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Emergency Contact</Typography>
                           <Typography variant="body2">Only administrators can view project employee emergency contact information, this is here for quick reference and to make first contact with an employees contact if there has been an incident.  Along with the contact information is some general medical information that may be relevant for first responders or medical staff.</Typography>
                           <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-home.png" alt="Start from home or any screen" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-profile-sidenav.png" alt="Click the top right navigation to reveal side nav" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-admin-list-emerg.png" alt="Select 'Emergency Contact'" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-admin-selected-emerg.png" alt="Search with name or through list" />
                           </div>
                           <Typography variant="h6">Add User Emergency Contact</Typography>
                           <Typography variant="body2">If after looking up an employee the information is not completed, you can edit it here as well as on the web.</Typography>
                           <img style={{marginTop:'10px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/manage-personnel/mobile-add-emerg-contact.png" alt="Set user emergency contact information" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Set Profile Picture</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(3)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'From Home Screen tap the Profile Avatar top left',
                                'Give permission to select images from your camera roll',
                                'Select an image'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Add a Profile Picture</Typography>
                           <Typography variant="body2">From the home screen where you see your profile avatar in the top left, you should be able to tap it and upload a new picture at any time.</Typography>
                           <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-home.png" alt="Home screen, tap avatar" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-permissions-camera.png" alt="Give permission to view your camera roll" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-camera-reel.png" alt="Select" />
                           </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Edit Personal Picture(s)</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(5)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'From Home screen tap the small camera icon near profile photo',
                                'Or Navigate to Profile from bottom nav or side nav',
                                'Tap the image + icon displayed underneath',
                                'Give permission and select photo to be added',
                                'Tap the X on a photo to remove it'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Editing Personal Photos</Typography>
                           <Typography variant="body2">At Budee we encourage employees to upload reminders of why they need to stay safe, it will be shown once daily in a reel when opening the application.  To edit/manage your personal photos you can tap the camera icon on the home screen or navigate directly to your profile and upload from there.</Typography>
                           <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-home.png" alt="Home Screen" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-profile.png" alt="Profile" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-camera-reel.png" alt="Upload" />
                           </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Accordion>
                      <AccordionSummary
                        style={{backgroundColor:theme.palette.primary.main}}
                        expandIcon={<ExpandMoreIcon color="secondary" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{color:'white'}}>Set Profile Picture</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                        <StepTimeline
                          title="Quick Reference"
                          list={[...Array(3)].map((_, index) => ({
                            id: `item-${index}`,
                            title: [
                              'From Home Screen tap the Profile Avatar top left',
                              'Give permission to select images from your camera roll',
                              'Select an image'
                            ][index],
                            type: `order${index + 1}`
                          }))}
                         />
                         <Typography variant="h6">Add a Profile Picture</Typography>
                         <Typography variant="body2">From the home screen where you see your profile avatar in the top left, you should be able to tap it and upload a new picture at any time.</Typography>
                         <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                           <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-home.png" alt="Home screen, tap avatar" />
                           <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-permissions-camera.png" alt="Give permission to view your camera roll" />
                           <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-camera-reel.png" alt="Select" />
                         </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        style={{backgroundColor:theme.palette.primary.main}}
                        expandIcon={<ExpandMoreIcon color="secondary" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{color:'white'}}>Edit Personal Picture(s)</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                        <StepTimeline
                          title="Quick Reference"
                          list={[...Array(5)].map((_, index) => ({
                            id: `item-${index}`,
                            title: [
                              'From Home screen tap the small camera icon near profile photo',
                              'Or Navigate to Profile from bottom nav or side nav',
                              'Tap the image + icon displayed underneath',
                              'Give permission and select photo to be added',
                              'Tap the X on a photo to remove it'
                            ][index],
                            type: `order${index + 1}`
                          }))}
                         />
                         <Typography variant="h6">Editing Personal Photos</Typography>
                         <Typography variant="body2">At Budee we encourage employees to upload reminders of why they need to stay safe, it will be shown once daily in a reel when opening the application.  To edit/manage your personal photos you can tap the camera icon on the home screen or navigate directly to your profile and upload from there.</Typography>
                         <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                           <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-home.png" alt="Home Screen" />
                           <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-profile.png" alt="Profile" />
                           <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/profile/mobile-camera-reel.png" alt="Upload" />
                         </div>
                      </AccordionDetails>
                    </Accordion>
                  </>
                }
              </>
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
