import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Container } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import StepTimeline from '../components/widgets';
import SearchContext from '../components/context';

export default function ManagePersonnel() {
  const config = useContext(SearchContext);
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Training | Certifications </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" gutterBottom>Certification Management: Digital Oversight for Enhanced Safety and Compliance</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Welcome to Budee's Certification Management Module</Typography>
            <Typography variant="body2" gutterBottom>Safety compliance is a non-negotiable aspect of competent and secure work environments, particularly when specialized machinery and high-risk situations are involved. Budee's Certification Management Module is not just a digital solution but a strategic tool that significantly elevates your compliance monitoring capabilities.</Typography>
            <Typography variant="h5" gutterBottom>Benefits of Digital Compliance Records</Typography>
            <Typography variant="body2" gutterBottom>Digitizing the certification process allows for real-time updates and instant access to critical compliance information. This eliminates the need for cumbersome paperwork and significantly reduces the time required for audits, thereby enhancing operational efficiency. Stored securely in a digital vault, these records can be quickly retrieved for compliance audits or immediate verification, making your organization more agile and responsive to safety needs.</Typography>
            <Typography variant="h5" gutterBottom>Essential Video Guidance</Typography>
            <Typography variant="body2" gutterBottom>For a deeper understanding and more effective use of this module, we highly recommend watching the video tutorial below. The tutorial provides valuable insights into maximizing the platform’s capabilities for safety compliance and risk mitigation.</Typography>
            <Typography variant="h5" gutterBottom>Navigating the Certification Management Landscape</Typography>
            <Typography variant="body2" gutterBottom>To begin your journey, log in to the administrative portal and head to the 'Certification' section. Here, you can manage and monitor all required certifications for your workforce, maintaining uniformity across all job sites. When a certification is checked as 'required,' it is mandated for that particular jobsite, ensuring that only qualified personnel are operating in each respective environment.</Typography>
            <Typography variant="body2" gutterBottom>By taking advantage of our digital Certification Management Module and accompanying video tutorial, you are making a significant stride in risk mitigation, ensuring both the safety and competency of your workforce.</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Setting Up Certification Expiry Alerts: Timely Renewals for Continuous Compliance</Typography>
            <Typography variant="h5" gutterBottom>Understanding the Expiry Alert Feature</Typography>
            <Typography variant="body2" gutterBottom>Certification renewals are crucial for maintaining a safe and compliant workplace. Budee allows you to set an advanced warning period, expressed in days, which triggers automated alerts for upcoming certification expirations.</Typography>
            <Typography variant="h5" gutterBottom>How the Expiry Alert Mechanism Operates</Typography>
            <Typography variant="body2" gutterBottom>By default, Budee is set to flag certifications that are 30 days away from expiration. Once a certification reaches this threshold, it will be highlighted both on the mobile home screen and the web dashboard, indicating that renewal is due soon.</Typography>
            <Typography variant="h5" gutterBottom>Customizing Your Warning Period</Typography>
            <Typography variant="body2" gutterBottom>The ideal duration for your warning period depends on the time it usually takes to renew a particular certification. If a certification can be renewed within a week, then setting the alert threshold at 7 days may suffice. For certifications that take longer to renew, adjust the warning period according to the typical renewal timeframe. This ensures that employees have adequate time to complete the renewal process without lapsing into non-compliance.</Typography>
            <Typography variant="body2" gutterBottom>By leveraging this customizable alert feature, you're taking an active role in risk mitigation and ensuring the continuous safety and competency of your workforce.</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Role-Specific Certification Requirements: Targeted Compliance for Enhanced Efficiency</Typography>
            <Typography variant="h5" gutterBottom>Guiding Principle of Information Relevance in Budee</Typography>
            <Typography variant="body2" gutterBottom>Budee's system is engineered to display only the most pertinent information tailored to each employee's role, thereby streamlining user experience and focusing on job-specific compliance needs.</Typography>
            <Typography variant="h5" gutterBottom>Setting Universal or Role-Specific Certifications</Typography>
            <Typography variant="body2" gutterBottom>If a certification is essential for all staff members, simply designate it as 'Project Wide,' making it a requirement across all roles. Conversely, if a certification is only applicable to specific roles or contractors, you can customize the settings to display the certification only to those particular groups.</Typography>
            <Typography variant="body2" gutterBottom>By utilizing these targeted compliance features, you're enhancing the relevance and efficiency of the certification process, ensuring that each employee sees only what is necessary for their role, thereby improving compliance and reducing information clutter.</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5">Video Tutorial</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/EeIaYB68CtQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Properties</Typography>
            <TableContainer color="secondary" component="div">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Description</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Example Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Required By</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>In Budee, we attempt to ensure users only see relevant information to their job, if you want a certification to be required by everyone simply leave it as Project Wide.  If you want only certain users who have been assigned a specific role or a contractor to be required to submit you can select as many roles/contractors as you like and only users with those assigned role or contractor will see the document available to them.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Project-Wide</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Name</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>This is the recognizable name like “OSHA 10-Hour”, “Bloodborne Pathogens” or “Confined Space Entry”.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>OSHA 10 Hour</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Description</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>This is any additional, even long form description of the certification.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Please upload an image of the certification you received when you completed your OSHA 10 Hour course.</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Days to Expire</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>This integer will represent how many days in advance warning the system will begin to highlight a potentially expiring certification.  Once the expiration date of a submitted certification is less than this number, as in by default 30 days from expiration, it will then be highlighted on the mobile home screen and the web dashboard that someone is soon to be expiring.  The advice for what this number should be is as follows, however long it takes for someone to renew a certification should dictate the warning period.  If it's simple, like can be renewed easily within a week 7 days might suffice, if it's more difficult to renew then however long it typically takes should dictate the days to warning.  This gives the employee ample time to renew their certification.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>This value won’t actually matter in this example, OSHA 10 hour is not a certification that expires so we will just leave this value as 30</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <Typography variant="h5" style={{flexGrow:1}}>Quick Reference for Basic Operations</Typography>
              <Typography variant="h6">{config && config.platform === 'web' ? 'Web' : 'Mobile'} - {config && config.user === 'admin' ? 'Admin' : 'Basic'}</Typography>
              <Tooltip title="Select the Platform and User Status in the top left corner, then you can view the perspective on the web or mobile for a basic or administrator.">
                <HelpIcon color="secondary" />
              </Tooltip>
            </div>
            {
              config && config.platform === 'web' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Add a Certification</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(5)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Certifications"',
                                'Click the "Add New Certification" button',
                                'Fill out the fields in the form',
                                'Once added, in order to make the certification required you have to check the box in the table for each project',
                                'Change projects with the selector in the top left'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Adding a Certification</Typography>
                           <Typography variant="body2">Any certification that gets added is added to the entire company, each project then has the same table of certifications but will only show on the mobile if it is checked off as required.  View the leftmost column in the table on the certifications page to ensure its required.</Typography>
                           <Typography variant="body2">
                             <ul style={{marginLeft:'25px'}}>
                              <li>Name - Name of the certification recognized by employees</li>
                              <li>Description - Just a generic description of what this is and what if anything in particular you are looking for from the employee.</li>
                              <li>Days to Expiration - This field will dictate when an "Expires Soon" warning will show up to the employee and administrators.  Try to make this value equivalent to how long it would take an employee to renew their certification.</li>
                              <li>Required By - If this certification is only intended for certain roles, they can be selected and added here.  Document shows to everyone unless a role is added.</li>
                             </ul>
                           </Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/certifications/web-admin-cert-list.png" alt="View list of certifications" />
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/certifications/web-admin-cert-add.png" alt="Add a new certification" />
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/certifications/web-admin-cert-req.png" alt="Make it required at the project" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Viewing Compliance of Certifications</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(5)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Dashboard"',
                                'Click on the "Certifications" tab',
                                'View the pie charts showing compliance, click on chart to get breakdown below',
                                'Click on a name in the "Unverified" column to inspect and verify'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Viewing Compliance in Certifications</Typography>
                           <Typography variant="body2">To get a high level view of the project, seeing who is compliant can be viewed on the dashboard in pie chart format.  When diving into the data, click on the pie chart and then verify all uploaded certifications have the information you require and verify them.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/certifications/web-admin-dashboard-cert.png" alt="View certifications" />
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/certifications/web-admin-dashboard-verify.png" alt="Verify by clicking on an unverified name" />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Typography variant="h6">No Permissions</Typography>
                    <Typography variant="body2">Basic users have no permssions to manage certifications at a job site.</Typography>
                  </>
                }
              </>
            }
            {
              config && config.platform === 'mobile' &&
              <>
                {
                  (config.user === 'admin' || config.user === 'basic') &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>View/Edit Required Certifications</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(5)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'On the "Home" screen',
                                'Tap the accordion that shows "Certifications"',
                                'If there is no certifications accordion, then there are none required',
                                'See a list of All Cerifications with color coded status',
                                'Tap a Certification to make a submission'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">View and Edit Certifications</Typography>
                           <Typography variant="body2">Navigate to the home screen, you can see amongst other green bars (accordions) that there may be required certifications.  Tap it to reveal and then you are shown only the ones you are missing, tap the "Show All" to view all certifications.  Tap a certification to update it or submit for the first time.</Typography>
                           <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/certifications/mobile-home.png" alt="View the home screen" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/certifications/mobile-cert-attention.png" alt="See attention required certifications" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/certifications/mobile-all-certs.png" alt="See all certifications" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/certifications/mobile-add-cert.png" alt="Edit Certification" />
                           </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
              </>
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
