import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Container } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import StepTimeline from '../components/widgets';
import SearchContext from '../components/context';

export default function Observations() {
  const config = useContext(SearchContext);
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Training | Observations </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h5" gutterBottom>Safety Observations: Proactive Task Management for a Safer Workspace</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Introducing Budee's Safety Observations Module</Typography>
            <Typography variant="body2" gutterBottom>Welcome to Budee’s Safety Observations Module, designed to facilitate proactive safety management. In essence, 'observations' in this context are tasks aimed at identifying and rectifying unsafe conditions in the workplace.</Typography>
            <Typography variant="h5" gutterBottom>The Multifaceted Nature of Observations</Typography>

            <Typography variant="body2" gutterBottom>Observations within the system are highly versatile:</Typography>
            <ol>
              <li><Typography variant="body2">Employee-Centric Reporting: Any employee can easily log a safety observation via their mobile device.</Typography></li>
              <li><Typography variant="body2">Integrative Reporting: Observations can be directly linked to various fields in reports, identifiable by an icon for quick referencing and recording.</Typography></li>
              <li><Typography variant="body2">Smart Triggers: Certain fields in documents may have triggers attached to options (like radio groups, select boxes, or checklists) that will prompt an employee to record an observation upon interaction.</Typography></li>
            </ol>
            <Typography variant="h5" gutterBottom>How to Record an Observation</Typography>
            <Typography variant="body2" gutterBottom>To start, open the mobile app and locate the 'eyeball' icon in the bottom corner, which is your gateway to the Observations section. Click on "Report New Safety Observation" at the top of the screen, and a form will appear for you to fill out.</Typography>
            <Typography variant="body2" gutterBottom>By leveraging the Safety Observations Module, you're enabling a culture of proactive safety, ensuring that potential risks are promptly identified, recorded, and addressed by the relevant parties.</Typography>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5">Video Tutorial(s)</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/jKdNmjavmtU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <Typography variant="h5" gutterBottom>Properties</Typography>
            <TableContainer color="secondary" component="div">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Name</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Description</TableCell>
                    <TableCell sx={{backgroundColor:theme.palette.secondary.main}}>Example Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Description</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Quick or even long form description of what is unsafe or the task that needs to be performed to resolve this issue.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>"A first and second shift employee was not wearing protective sleeve while disc grinding"</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Photograph(s)</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Take pictures of the unsafe condition or jobsite, as many as needed will be attached to this observation.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}></TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Assign To</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Any employee currently on-site at this project location.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Mattie Samuels</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Report Anonymously</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>If an employee wants to anonymously report an observation, their name will be hidden in the reports throughout the application.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Unchecked</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{color:'#ffffff'}} component="th" scope="row">Fix By Date</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>If an appropriate deadline for the resolution of this observation is required, this can be set here.</TableCell>
                    <TableCell sx={{color:'#ffffff'}}>Unchecked</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Divider color="primary" style={{marginTop:'20px',marginBottom:'20px'}} />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <Typography variant="h5" style={{flexGrow:1}}>Quick Reference for Basic Operations</Typography>
              <Typography variant="h6">{config && config.platform === 'web' ? 'Web' : 'Mobile'} - {config && config.user === 'admin' ? 'Admin' : 'Basic'}</Typography>
              <Tooltip title="Select the Platform and User Status in the top left corner, then you can view the perspective on the web or mobile for a basic or administrator.">
                <HelpIcon color="secondary" />
              </Tooltip>
            </div>
            {
              config && config.platform === 'web' &&
              <>
                {
                  config.user === 'admin' &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>View Observations Submitted Dashboard</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Dashboard"',
                                'Click the "Observations" Tab',
                                'View a chart showing observation submissions over time',
                                'Click any dates to show what was submitted in that time period'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">View Observations on Dashboard</Typography>
                           <Typography variant="body2">Any observations that are submitted by personnel at the selected project will show up on the dashboard and the chart provides trending information.  Select any date by clicking on the chart and control the time periods on the left and top right of the charts.</Typography>
                           <img src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/observations/web-admin-dashboard.png" alt="Viewing observations on the dashboard" />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>View Observations History With Filters</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(3)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Observations"',
                                'On top of the table are various filters to control the data in the table',
                                'Click on any row to open the pop-up for more details'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">View Observations With Filters</Typography>
                           <Typography variant="body2">Viewing all the observations at a project is available here, there are various filters provided to search for assigned to, fix by date, reported by, resolved by or if they were attached to a document submission.</Typography>
                           <img style={{marginTop:'10px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/observations/web-admin-observations.png" alt="View observations at a project" />
                           <img style={{marginTop:'10px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/observations/web-admin-filters.png" alt="Control the table data by filtering" />
                           <img style={{marginTop:'10px'}} src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/observations/web-admin-popup.png" alt="View details of an observation" />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
                {
                  config.user === 'basic' &&
                  <>
                    <Typography variant="h6">No Permissions</Typography>
                    <Typography variant="body2">Basic users have no permssions to view observations on the web at a job site.</Typography>
                  </>
                }
              </>
            }
            {
              config && config.platform === 'mobile' &&
              <>
                {
                  (config.user === 'admin' || config.user === 'basic') &&
                  <>
                    <div>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Report An Observation</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(3)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to Observations on bottom nav',
                                'Click the "Report Observation" Button',
                                'Fill out the details and Report'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Report Observation</Typography>
                           <Typography variant="body2">Navigate to the observation home screen by tapping the bottom eyeball icon.  This is the home screen and the top button is Report Observations which will open the form to fill out and publish an observation.</Typography>
                           <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/observations/mobile-obv-home.png" alt="Navigate to observation home screen" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/observations/mobile-report-observation.png" alt="Click the report observation button and publish a new observation" />
                           </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>View Observations</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(4)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to Observations on bottom nav',
                                'The dropdown has 4 options for viewing observations',
                                'Select the perferred type of observations to view',
                                'Tap any observation in the list to view more details'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Viewing Observations</Typography>
                           <Typography variant="body2">Navigate to the observations home screen, use the dropdown to select the observations you'd like to see.  Tap on any observation to view more details and/or resolve an open one.</Typography>
                           <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',width:'100%'}}>
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/observations/mobile-obv-home.png" alt="Observations home screen" />
                             <img style={{marginTop:'10px',marginLeft:'5px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/observations/mobile-select-view.png" alt="Select different views from the dropdown" />
                           </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          style={{backgroundColor:theme.palette.primary.main}}
                          expandIcon={<ExpandMoreIcon color="secondary" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{color:'white'}}>Observations Assigned To You</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{backgroundColor:'#cdcdcd'}}>
                          <StepTimeline
                            title="Quick Reference"
                            list={[...Array(2)].map((_, index) => ({
                              id: `item-${index}`,
                              title: [
                                'Navigate to "Home"',
                                'If an observation is assigned to you it will show up and can be viewed by tapping the accordion'
                              ][index],
                              type: `order${index + 1}`
                            }))}
                           />
                           <Typography variant="h6">Viewing Observations Assigned To You</Typography>
                           <Typography variant="body2">Navigate to the home screen and any open observations assigned to you will be there.</Typography>
                           <img style={{marginTop:'10px'}} width="200px" src="https://dev-budeestatic.s3.us-west-2.amazonaws.com/training/observations/mobile-assigned-observation.png" alt="Home screen shows assigned observations" />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                }
              </>
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
