import { useState, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import queryString from 'query-string';

//
import Nav from './nav';
import SearchContext from '../../components/context';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  color: '#ffffff'
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  backgroundColor:'#565656',
  paddingTop: 20,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const [platform, setPlatform] = useState('web');
  const [user, setUser] = useState('admin');
  const { search } = useLocation();

  useEffect(() => {
    const parsed = queryString.parse(search);
    if (parsed?.platform) {
      setPlatform(parsed.platform);
    }
    if (parsed?.user) {
      setUser(parsed.user);
    }
  }, [search]);

  const contextValue = useMemo(() => ({
    user,
    platform
  }), [user, platform]);

  return (
    <StyledRoot>
      <SearchContext.Provider value={contextValue}>
        <Nav openNav={open} onCloseNav={() => setOpen(false)} />
        <Main>
          <Outlet />
        </Main>
      </SearchContext.Provider>
    </StyledRoot>
  );
}
