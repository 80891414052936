
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import BuildIcon from '@mui/icons-material/Build';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

// import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// import BusinessIcon from '@mui/icons-material/Business';
// import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import AccountTreeIcon from '@mui/icons-material/AccountTree';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import GroupIcon from '@mui/icons-material/Group';


const navConfig = [
  {
    title: 'introduction',
    path: '/intro',
    icon: <InfoIcon />,
  },
  {
    title: 'getting started',
    path: '/started',
    icon: <PlayCircleOutlineIcon />,
  },
  {
    title: 'profile',
    path: '/profile',
    icon: <AccountCircleIcon />,
  },

  {
    title: 'documents',
    path: '/documents',
    icon: <DescriptionIcon />,
  },
  {
    title: 'certifications',
    path: '/certifications',
    icon: <BeenhereIcon />,
  },
  {
    title: 'observations',
    path: '/observations',
    icon: <VisibilityIcon />,
  },
  {
    title: 'training',
    path: '/training',
    icon: <BuildIcon />,
  },
  {
    title: 'alerts',
    path: '/alerts',
    icon: <NotificationsActiveIcon />,
  },
  {
    title: 'manage personnel',
    path: '/manage-personnel',
    icon: <GroupAddIcon />,
  },
  {
    title: 'rewards',
    path: '/rewards',
    icon: <CardGiftcardIcon />,
  }
];

export default navConfig;
